import _ from 'lodash';
import { IconType } from 'react-icons';
import loadable from "@loadable/component";

export interface IconProps {
  src?: string;
  name?: string;
  className?: string;
  [k: string]: any;
}

export default function Icon({ src, name, className, ...props }: IconProps) {
  const lib = 'io5'; let elem;
  if (name?.startsWith('Io')) {
    const CustomIcon = loadable(() => import(`react-icons/${lib}/index.js`), {
      resolveComponent: (el: JSX.Element) =>
        el[name as keyof JSX.Element || ''] as IconType
    });
    elem = <CustomIcon style={{ verticalAlign: 'middle', height: 24 }} className={`custom-icon ${className ?? ''}`} {...props} />
  } else {
    elem = <span className={`custom-icon ${className ?? ''}`} {...props}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src={src || `/assets/icons/${name}.svg`} alt='' />
    </span>
  }

  return elem;
}
