import { NextPage } from "next";
import Link from "next/link";
import Icon from '@components/Icon';
import { useRouter } from "next/router";
import CreateContestModal from "@components/modals/CreateContest";
import { useAppDispatch, useAppSelector } from "@hooks";
import { toggleModal } from "@store/ui";
import { Modals } from "@store/ui/types";
import ProfileMenuDropdown from "@components/popovers/ProfileMenuDropdown";
import { useCallback, useEffect, useState } from "react";
import Avatar from '@components/Avatar';
import { IUser } from "@services/user/types";
import Logo from "@components/Logo";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

interface HeaderProps {
  user?: IUser;
  isLandingPage?: boolean;
}

const Header: NextPage<HeaderProps> = function ({ user, isLandingPage }) {
  const router = useRouter();
  const isActive = (path: string) => router.pathname.split('/')[1] === path;
  const { ui: { modals } } = useAppSelector(state => state);
  const dispatch = useAppDispatch();

  const [navbarOpen, setNavbarOpen] = useState(false);

  const { create } = router.query;

  const openCreateContestModal = useCallback(() => {
    if (!user) {
      const redirectUrl = `/auth/login?continue=${encodeURIComponent(router.asPath + '?create=1')}`;
      return router.push(redirectUrl);
    }

    return dispatch(toggleModal({ name: Modals.CreateContest, props: { user } }))
  }, [dispatch, router, user]);

  useEffect(() => {
    if (create === '1') {
      openCreateContestModal();
    }
  }, [create, openCreateContestModal]);

  return (
    <>
      <header id="main-header" className={`bg-primary ${isLandingPage ? "landing-page" : ""}`}>
        <Navbar
          dark
          expand="lg"
          className={`px-md-5 px-xxl-auto ${isLandingPage && navbarOpen ? "bg-primary" : "bg-transparent"}`}>
          <NavbarBrand className="pe-md-4" href="/">
            <Logo showText={!isLandingPage && navbarOpen} />
          </NavbarBrand>

          {!isLandingPage && !navbarOpen &&
            <button onClick={() => openCreateContestModal()} className="btn btn-secondary text-light header-cta" aria-current="page">
              <Icon name="plus" className='me-1'></Icon>
              Create Contest
            </button>
          }

          <NavbarToggler onClick={() => setNavbarOpen(!navbarOpen)} />

          <Collapse isOpen={navbarOpen} navbar>
            <Nav className="ms-auto" navbar>
              <NavItem>
                <NavLink active={isActive('contests')} href="/contests">
                  Explore
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink active={isActive('pricing')} href="/pricing">
                  Pricing
                </NavLink>
              </NavItem>

              {user && isLandingPage && (
                <NavItem>
                  <NavLink active={isActive('dashboard')} href="/dashboard">
                    My Dashboard
                  </NavLink>
                </NavItem>
              )}


              {/* // todo: implement search contests */}
              {/* <NavItem className="search-wrapper">
                <div className="input-group bg-light">
                  <input type="search" className="form-control" name="contest" placeholder="Search Contests" />
                  <button className="btn btn-link search-btn">
                    <Icon name="search"></Icon>
                  </button>
                </div>
              </NavItem> */}

              {!user && <NavItem className='d-flex align-items-center text-muted text-uppercase'>
                <NavLink href="/auth/login">
                  Login
                </NavLink>
                |
                <NavLink href="/signup">
                  Signup
                </NavLink>
              </NavItem>}

              <NavItem className={`${navbarOpen && 'mt-3'}`}>
                <button onClick={() => openCreateContestModal()} className="btn create-contest" aria-current="page">
                  <Icon name="plus" className='me-1'></Icon>
                  Create Contest
                </button>
              </NavItem>

              {user && !navbarOpen && <>
                {/* // todo: implement notifications */}
                {/* <NavItem>
                      <button className="btn btn-link">
                        <span className="icon-wrapper position-relative">
                          <Icon name="bell"></Icon>
                          <span className="new-notification">4</span>
                        </span>
                      </button>
                    </NavItem> */}
                <a className="nav-item">
                  <button className="btn p-0 d-flex align-items-center" type="button"
                    id="profileMenuBtn" aria-expanded="false">
                    <div className="user-avatar">
                      <Avatar src={user?.avatar} name={user?.name} size='40' round={true} className='mr-2' />
                    </div>
                    <span className="ms-2">
                      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.54736 1L4.69831 5L0.849251 1" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round" />
                      </svg>
                    </span>
                  </button>
                </a>
                <ProfileMenuDropdown
                  target='profileMenuBtn'
                  user={user} />
              </>}
            </Nav>
          </Collapse>
        </Navbar>
      </header>

      {/* Create Contest Modal */}
      <CreateContestModal
        showModal={modals.createContest.isOpen}
        {...modals.createContest.props}
        toggle={() => dispatch(toggleModal({ name: Modals.CreateContest }))}
      ></CreateContestModal>
    </>
  );
};

export default Header;
