import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, UncontrolledCollapse, UncontrolledTooltip } from 'reactstrap';
import { showToast } from '@helpers/util';
import { NextPage } from 'next';
import { CreateContestSchema } from '@services/contest/schema';
import { useForm } from 'react-hook-form';
import { ContestStatus, CreateContestOptions, IContest } from '@services/contest/types';
import Icon from '@components/Icon';
import Link from 'next/link';
import Avatar from '@components/Avatar';
import { createContest, removeContest, updateContest, updateContestImage } from '@services/contest';
import _ from 'lodash';
import { useRouter } from 'next/router';
import CreateContestantForm from '@components/form/CreateContestant';
import { format, formatISO, set } from 'date-fns';
import RichTextInput from '@components/form/Input/RichTextInput';
import { Currency } from '@services/shared/types';
import { IUser, UserSubscriptionStatus } from '@services/user/types';
import UpgradeButton from '@components/UpgradeButton';
import { PlanType } from '@services/plan/types';

interface CreateContestModalProps {
  showModal: boolean;
  toggle: () => void;
  onClosed?: () => void;
  contest?: IContest;
  user?: IUser;
}

const CreateContestModal: NextPage<CreateContestModalProps> = function ({ showModal, toggle, onClosed: handleOnClosed, contest: existingContest, user }) {
  const defaultValues: CreateContestOptions = {
    title: existingContest?.title || '',
    description: existingContest?.description || '',
    isContestPrivate: existingContest?.isContestPrivate || false,
    votingStarts: existingContest?.votingStarts || '',
    votingEnds: existingContest?.votingEnds || '',
    status: existingContest?.status || ContestStatus.Draft,
    isRegistrationPrivate: existingContest?.isRegistrationPrivate || true,
    // isVotingPrivate: false,
    registrationStarts: existingContest?.registrationStarts,
    registrationEnds: existingContest?.registrationEnds,
    votingFee: {
      value: 0,
      currency: Currency.NGN
    } || {...existingContest?.votingFee},
    entryFee: {
      value: 0,
      currency: Currency.NGN
    } || {...existingContest?.entryFee},
  };
  const router = useRouter();

  const [avatar, setAvatar] = useState<string>(existingContest?.image || '');
  const [loading, setLoading] = useState(false);
  const [contest, setContest] = useState<IContest>();
  const [contestCreated, setContestCreated] = useState(false);
  const isPremiumUser = user?.activePlan?.type === PlanType.Premium;

  const { formState: { errors, isSubmitting, isSubmitted, isValid, dirtyFields }, watch, setValue, getValues, register, handleSubmit, reset } = useForm<CreateContestOptions>({
    defaultValues,
    mode: 'onChange',
    resolver: CreateContestSchema as any
  });

  const { status, isContestPrivate, title, votingStarts, votingEnds, registrationStarts, registrationEnds, description, entryFee, votingFee } = getValues();

  useEffect(() => {
    if (!existingContest) return;
    // assign existing contest fields
    setContest(existingContest);
    setAvatar(existingContest.image);
    setValue('title', existingContest.title, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('description', existingContest.description || '', { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('isContestPrivate', existingContest.isContestPrivate, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('votingStarts', new Date(existingContest.votingStarts || Date.now()).toISOString(), { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('votingEnds', new Date(existingContest.votingEnds || Date.now()).toISOString(), { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('registrationStarts', new Date(existingContest.registrationStarts || Date.now()).toISOString(), { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('registrationEnds', new Date(existingContest.registrationEnds || Date.now()).toISOString(), { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('status', existingContest.status, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('isRegistrationPrivate', existingContest.isRegistrationPrivate, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('entryFee', {currency: existingContest.entryFee.currency, value: Number(existingContest.entryFee.value)}, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('votingFee', {currency: existingContest.votingFee.currency, value: Number(existingContest.votingFee.value)}, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  }, [existingContest, setValue]);


  // create contest with title to get an ID for this session
  const createNewContest = useCallback(async () => {
    const newContest = await createContest({ title })
      .then(res => {
        setContestCreated(true);
        return res.data;
      })
      .catch(err => {
        /* toast: typeof err === 'string' ? err : err.message, 'error' */;
      });
    setContest(newContest || {} as any);
    setValue('title', newContest?.title as string, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  }, [setValue, title]);

  useEffect(() => {
    watch(['status', 'isContestPrivate', 'title', 'votingStarts', 'votingEnds', 'registrationStarts', 'registrationEnds']);
  }, [watch]);

  useEffect(() => {
    if (showModal) {
      // if existing contest, setContest else show create dialog
      // createDemoContest();
      setContestCreated(Boolean(existingContest));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  // delete contest on "discard changes"
  const deleteDemoContest = () => {
    if (contest) {
      removeContest(contest._id)
        .then(() => router.push('/dashboard/contests'))
        .catch((err) => {
          /* toast: typeof err === 'string' ? err : err.message, 'error' */;
        });
    }
  }

  const onClosed = () => {
    reset(defaultValues)
    setAvatar('')
    setContest(undefined)
    setContestCreated(false)
    if (handleOnClosed) {
      return handleOnClosed();
    }
  }

  const onContestImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const contestImage = (e.target.files || [])[0];
    if (!contestImage) { return; }

    const reader = new FileReader();

    reader.onloadend = function () {
      const imageData = reader.result;
      setAvatar(imageData as string);

      if (contest) {
        updateContestImage(contest._id, contestImage)
          .then((res) => {
            setContest(res.data);
          }).catch(error => {
            /* toast: error, 'error' */;
            setAvatar(contest?.image);
          })
          .finally(() => setLoading(false));
      }
    };

    reader.readAsDataURL(contestImage);
  }

  const onsubmit = (data: CreateContestOptions) => {
    if (contest) {
      updateContest(contest?._id, data)
        .then(res => {
          reset(defaultValues);
          /* toast: 'Changes saved!', 'success' */;
          router.push(`/contests/${res.data.slug}/contestants`);
          toggle();
        })
        .catch(error => {
          /* toast: error, 'error' */;
        });
    }
  };

  const handleCreateContest = (status: ContestStatus) => {
    setValue('status', status, { shouldDirty: true });
    handleSubmit(onsubmit)();
  };


  const updateDateValue = (field: Record<string, any>, value: string) => {
    setValue(Object.keys(field)[0] as any, set(
      new Date(Object.values(field)[0] || Date.now()),
      {
        date: new Date(value).getDate(),
        month: new Date(value).getMonth(),
        year: new Date(value).getFullYear()
      }
    ).toISOString(), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    })
  }

  const updateTimeValue = (field: Record<string, any>, value: string) => {
    setValue(
      Object.keys(field)[0] as any,
      set(new Date(Object.values(field)[0] || Date.now()),
        {
          hours: Number(value.split(':')[0]),
          minutes: Number(value.split(':')[1])
        }
      ).toISOString(), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    })
  }

  return (
    <Modal size='lg'
      onClosed={onClosed}
      isOpen={showModal}
      toggle={toggle}
      fade={false}
      id='contest-modal'
      className='create-contest-modal modal-fullscreen-md-down modal-lg'>
      <header className="modal-header w-100 bg-primary px-md-5 py-3 d-flex justify-content-between align-items-center">
        <h3 className="text-capitalize fs-section-subtitle fw-bold text-light m-0">{existingContest ? 'Edit' : 'Create'} contest</h3>
        <button onClick={toggle} className="btn close-btn">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.4" cx="20" cy="20" r="20" fill="white" />
            <circle cx="20.0002" cy="20" r="16" fill="white" />
            <g clipPath="url(#clip0)">
              <path
                d="M18.8275 20.0108L13.8434 15.0266C13.519 14.7024 13.519 14.1782 13.8434 13.854C14.1676 13.5298 14.6918 13.5298 15.016 13.854L20.0002 18.8382L24.9843 13.854C25.3086 13.5298 25.8327 13.5298 26.1569 13.854C26.4812 14.1782 26.4812 14.7024 26.1569 15.0266L21.1728 20.0108L26.1569 24.995C26.4812 25.3192 26.4812 25.8434 26.1569 26.1676C25.9953 26.3293 25.7829 26.4106 25.5706 26.4106C25.3583 26.4106 25.146 26.3293 24.9843 26.1676L20.0002 21.1834L15.016 26.1676C14.8543 26.3293 14.642 26.4106 14.4297 26.4106C14.2174 26.4106 14.0051 26.3293 13.8434 26.1676C13.519 25.8434 13.519 25.3192 13.8434 24.995L18.8275 20.0108Z"
                fill="#25282B" />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="12.8" height="12.8" fill="white" transform="matrix(-1 0 0 1 26.4001 13.5996)" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </header>
      <ModalBody className='p-4 content'>
        <p>
          <span className="text-danger">*</span> &mdash; Indicates a required field
        </p>
        {/* Name and slug */}
        <section>
          <div className="row">
            {contestCreated &&
              <div className="col-auto">
                <div className="image-wrapper">
                  {loading &&
                    <div className='image-loading text-uppercase text-center'>Saving...</div>
                  }

                  {!loading &&
                    <label htmlFor="contestPhoto" className="text-uppercase text-center h-100 fw-normal lh-sm">
                      {avatar && <Avatar src={avatar} name={title} size='100%' />}
                      {!avatar && <>click to add <span className="text-dark fw-bold">photo</span></>}
                    </label>
                  }
                  <input type="file" id="contestPhoto" onChange={onContestImageChange} aria-hidden="true" className='d-none' />
                </div>
              </div>}
            <div className='col'>
              <label htmlFor="contestName" className="text-uppercase text-dark fs-label fw-semi-bold mb-2 col-12">
                contest name{contestCreated && <> &amp; photo</>}<span className="text-danger">*</span>
              </label>

              <input type="text" id="contestName" className='form-control'
                {...register('title')}
                placeholder={`e.g Most Beautiful Girl Nigeria ${new Date().getFullYear()}`} />
              {errors?.title?.message && <small className='text-danger text-left m-0'>{errors?.title?.message}</small>}
            </div>
            {contestCreated && <div className="col-12 info my-2">
              <span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8.808 12.808H7.192V11.192H8.807L8.808 12.808ZM8.8 10.385H7.2C7.2 7.808 9.6 7.993 9.6 6.393C9.59816 5.96922 9.429 5.56332 9.12934 5.26366C8.82968 4.964 8.42378 4.79484 8 4.793C7.57751 4.79895 7.17399 4.96943 6.87521 5.2682C6.57643 5.56698 6.40595 5.9705 6.4 6.393H4.8C4.8 5.54431 5.13715 4.73037 5.73726 4.13026C6.33738 3.53014 7.15131 3.193 8 3.193C8.8487 3.193 9.66263 3.53014 10.2627 4.13026C10.8629 4.73037 11.2 5.54431 11.2 6.393C11.2 8.388 8.8 8.615 8.8 10.385Z"
                    fill="#3892EF" />
                </svg>
              </span>&nbsp;
              <p className="m-0 fs-label fw-normal d-inline">Photo must be JPG or PNG and less than 1MB</p>
            </div>}
          </div>

          {!contestCreated &&
            <div className="row">
              <button onClick={createNewContest} className="btn btn-secondary text-light w-100">
                Proceed
              </button>
            </div>}
        </section>

        {contestCreated && <>
          {/* General */}
          <section>
            <h3 className="text-capitalize text-primary fw-semi-bold fs-section-subtitle">general information</h3>
            <div className="row">
              <div className="col-12">
                <label htmlFor="contestDesc" className="text-uppercase text-dark fs-label fw-semi-bold mb-2">
                  contest description<span className="text-danger">*</span>
                </label>

                {/* <textarea {...register('description')}
                  placeholder='Give detailed information about your contest'
                  id="contestDesc" rows={7} className="form-control">
                </textarea> */}
                <RichTextInput value={description} onChange={(richData) => setValue('description', richData, { shouldDirty: true, shouldTouch: false, shouldValidate: true })} />
                {errors?.description?.message && <small className='text-danger text-left m-0'>{errors?.description?.message}</small>}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="contestPublic" className="text-uppercase text-dark fs-label fw-semi-bold mb-2">make contest
                  public</label>
                <p className="m-0 desc">Contest will {isContestPrivate ? 'only be accessed by visiting its URL' : 'be visible on the Expore Page as well as on search results'}</p>
              </div>
              <div className="col-auto align-self-center">
                <div className="form-check form-switch m-0">
                  {isPremiumUser && (
                    <input
                      defaultChecked={!isContestPrivate}
                      onChange={() => setValue('isContestPrivate', !isContestPrivate, { shouldDirty: true })}
                      className="form-check-input" type="checkbox" id="contestPublic" disabled={true} />
                  )}
                    {!isPremiumUser && <UpgradeButton />}
                </div>
              </div>
            </div>
          </section>

          {/* Registration */}
          <section>
             <div className="accordion accordion-flush">
                <div className="accordion-item">
                  <div className="accordion-header d-flex justify-content-between align-items-center w-100" id="registeration">
                    <div className="flex-grow-1">
                      <h3 className="text-capitalize text-primary fw-semi-bold fs-section-subtitle">
                        Registration setup
                        <a id="registration-info"><Icon name='IoHelpCircle' /></a>
                        <UncontrolledTooltip target="registration-info">
                          Setup the dates to open and close registration. Set a price for registration as well.
                        </UncontrolledTooltip>
                      </h3>
                    </div>

                    <button className="accordion-button d-block w-auto fs-label text-secondary fw-normal text-uppercase"
                      type="button" id="registrationCollapse">
                      toggle
                    </button>
                  </div>
                  <UncontrolledCollapse toggler="registrationCollapse">
                    <div className="accordion-body py-0 px-0">
                      {/* // todo: implement private and public registrations
                  {/* <div className="d-flex justify-content-between align-items-end">
                        <div className="d-flex flex-column justify-content-start flex-grow-1 me-3">
                          <label htmlFor="registerPublic" className="text-uppercase text-dark fs-label fw-semi-bold mb-2">make
                            registration
                            public</label>
                          <p className="m-0 desc">{isRegistrationPrivate ? 'Only invited persons' : 'Anyone'} will be able to register as a contestant for this contest</p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            defaultChecked={!isRegistrationPrivate}
                            onChange={(e) => setValue('isRegistrationPrivate', !e.target.checked, { shouldDirty: true })}
                            className="form-check-input" type="checkbox" id="registerPublic" />
                        </div>
                      </div> */}
                        {/* // todo: implement entry forms  */}
                  {/* <div className="d-flex justify-content-between align-items-end entry-form">
                        <div className="d-flex flex-column justify-content-start flex-grow-1 me-3">
                          <h4 className="text-uppercase text-dark fs-label fw-semi-bold mb-2">
                            entry form
                          </h4>
                          <p className="m-0 desc">Setup the info you want your contestant to provide upon registration</p>
                        </div>
                        <div>
                          <button className="btn text-uppercase fs-small fw-semi-bold text-light btn-primary">preview</button>
                          <button
                            className="btn text-uppercase fs-small fw-semi-bold text-grey ms-1 btn-grey">configure</button>
                        </div>
                      </div> */}
                  {/* <CreateContestantForm contest={contest as IContest}></CreateContestantForm> */}
                    <div className="accordion accordion-flush w-100">
                      <div className="accordion-item">
                        <div className="accordion-header d-flex justify-content-between align-items-center w-100 mt-3"
                          id="voting">
                          <div className="flex-grow-1">
                            <h4 className={`text-uppercase ${ isPremiumUser ? "text-dark" : "text-muted"} fs-label fw-semi-bold mb-2`}>
                              entry fee
                            </h4>
                            <p className={`m-0 desc ${!isPremiumUser ? "text-muted" : ""}`}>Contestants will be required to pay this fee to access the entry form
                            </p>
                          </div>

                          <button
                            className={`accordion-button ${ isPremiumUser ? 'd-block' : 'd-none'} w-auto fs-small text-grey fw-normal text-uppercase`}
                            type="button" id="entryfee">
                            set
                          </button>

                          {!isPremiumUser && <UpgradeButton />}
                        </div>
                        <UncontrolledCollapse toggler="entryfee">
                          <div className="accordion-body py-3 px-0 pb-0">
                            <div className="d-flex align-items-center justify-content-start">
                              <select
                                onChange={e => setValue('entryFee.currency', e.target.value as Currency)}
                                defaultValue={entryFee?.currency}
                                className="form-select w-auto">
                                <option value={Currency.NGN}>NGN</option>
                                <option value={Currency.USD}>USD</option>
                              </select>
                              <input
                                {...register("entryFee.value")}
                                type="number"
                                min={0}
                                className="mx-3 form-control w-auto" />
                            </div>
                            <div className="info my-2">
                              <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8.808 12.808H7.192V11.192H8.807L8.808 12.808ZM8.8 10.385H7.2C7.2 7.808 9.6 7.993 9.6 6.393C9.59816 5.96922 9.429 5.56332 9.12934 5.26366C8.82968 4.964 8.42378 4.79484 8 4.793C7.57751 4.79895 7.17399 4.96943 6.87521 5.2682C6.57643 5.56698 6.40595 5.9705 6.4 6.393H4.8C4.8 5.54431 5.13715 4.73037 5.73726 4.13026C6.33738 3.53014 7.15131 3.193 8 3.193C8.8487 3.193 9.66263 3.53014 10.2627 4.13026C10.8629 4.73037 11.2 5.54431 11.2 6.393C11.2 8.388 8.8 8.615 8.8 10.385Z"
                                    fill="#3892EF" />
                                </svg>
                              </span>
                              <p className="m-0 fs-label fw-normal d-inline">Leave at zero (0) if registration is free</p>
                            </div>
                          </div>
                        </UncontrolledCollapse>
                      </div>
                    </div>
            {/* // todo: implement registration start and end date */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row h-100 align-content-between">
                            <div className="col-12">
                              <h4 className="text-uppercase text-dark fs-label fw-semi-bold mb-2">Registration starts<span className="text-danger">*</span></h4>
                              <p className="m-0 desc">Set when registration for your contest will commence</p>
                            </div>
                            <div className="col-12">
                              <input type="date"
                                className='col-md-6 form-control mt-2'
                                defaultValue={format(new Date(registrationStarts || Date.now()), 'yyyy-MM-dd')}
                                onChange={(e) => updateDateValue({ registrationStarts }, e.target.value)}
                              />
                              <input type="time"
                                className="col-md-6 form-control mt-2"
                                defaultValue={format(new Date(registrationStarts || Date.now()), 'HH:mm')}
                                onChange={(e) => updateTimeValue({ registrationStarts }, e.target.value)}
                              />
                            </div>
                            {errors?.registrationStarts?.message && <small className='text-danger text-left m-0'>{errors?.registrationStarts?.message}</small>}
                          </div>
                        </div>
                        <div className="col-md-6 mt-3 mt-md-0">
                          <div className="row h-100 align-content-between">
                            <div className="col-12">
                              <h4 className="text-uppercase text-dark fs-label fw-semi-bold mb-2">registration ends<span className="text-danger">*</span></h4>
                              <p className="m-0 desc">Set when registration for your contest will close</p>
                            </div>
                            <div className="col-12">
                              <input type="date"
                                className='col-md-6 form-control mt-2'
                                defaultValue={format(new Date(registrationEnds || Date.now()), 'yyyy-MM-dd')}
                                onChange={(e) => updateDateValue({ registrationEnds }, e.target.value)}
                              />
                              <input type="time"
                                className="col-md-6 form-control mt-2"
                                defaultValue={format(new Date(registrationEnds || Date.now()), 'HH:mm')}
                                onChange={(e) => updateTimeValue({ registrationEnds }, e.target.value)}
                              />
                            </div>
                            {errors?.registrationEnds?.message && <small className='text-danger text-left m-0'>{errors?.registrationEnds?.message}</small>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
              </div>
          </section> 

          {/* Voting */}
          <section>
            <div className="accordion accordion-flush">
              <div className="accordion-item">
                <div className="accordion-header d-flex justify-content-between align-items-center w-100" id="voting">
                  <div className="flex-grow-1">
                    <h3 className="text-capitalize text-primary fw-semi-bold fs-section-subtitle">
                      voting setup&nbsp;
                      <a id="voting-info"><Icon name='IoHelpCircle' /></a>
                      <UncontrolledTooltip target="voting-info">
                        Setup the dates to open and close voting. Anyone can vote in this contest.
                      </UncontrolledTooltip>
                    </h3>
                  </div>

                  <button className="accordion-button d-block w-auto fs-label text-secondary fw-normal text-uppercase"
                    type="button" id="votingCollapse">
                    toggle
                  </button>
                </div>
                <UncontrolledCollapse toggler="votingCollapse">
                  <div className="accordion-body py-0 px-0">
                    {/* // todo: implement private/public voting */}
                    {/* <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex flex-column justify-content-start flex-grow-1 me-3">
                      <label htmlFor="registerPublic" className="text-uppercase text-dark fs-label fw-semi-bold mb-2">
                        Make voting public
                      </label>
                      <p className="m-0 desc">This contest will allow votes from {isVotingPrivate ? 'the selected voters' : 'the general public'}</p>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        defaultChecked={!isVotingPrivate}
                        onChange={e => setValue('isVotingPrivate', !e.target.checked, { shouldDirty: true })}
                        className="form-check-input" type="checkbox" id="votePublic" />
                    </div>
                  </div> */}
                    {/* // todo: implement voting prices */}
                    <div className="accordion accordion-flush w-100">
                    <div className="accordion-item">
                      <div className="accordion-header d-flex justify-content-between align-items-center w-100 mt-3"
                        id="voting">
                        <div className="flex-grow-1">
                          <h4 className={`text-uppercase ${isPremiumUser ? "text-dark" : "text-muted"} fs-label fw-semi-bold mb-2`}>
                            price per vote
                          </h4>
                          <p className={`m-0 desc ${!isPremiumUser ? "text-muted" : ""}`}>Contestants will be required to pay this fee to vote in this contest</p>
                        </div>

                        <button
                          className={`accordion-button ${isPremiumUser ? "d-block" : "d-none"} w-auto fs-small text-grey fw-normal text-uppercase`}
                          type="button" id="votingFee">
                          set
                        </button>

                        {!isPremiumUser && <UpgradeButton />}

                      </div>
                      <UncontrolledCollapse toggler="votingFee">
                        <div className="accordion-body py-3 px-0 pb-0">
                          <div className="d-flex align-items-center justify-content-start">
                            <select
                              onChange={e => setValue('votingFee.currency', e.target.value as Currency)}
                              defaultValue={votingFee?.currency}
                              className="form-select w-auto">
                              <option value={Currency.NGN}>NGN</option>
                              <option value={Currency.USD}>USD</option>
                            </select>
                            <input
                              {...register("votingFee.value")}
                              type="number"
                              min={0}
                              placeholder="0" className="mx-3 form-control w-auto" />
                          </div>
                          <div className="info my-2">
                            <span>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8.808 12.808H7.192V11.192H8.807L8.808 12.808ZM8.8 10.385H7.2C7.2 7.808 9.6 7.993 9.6 6.393C9.59816 5.96922 9.429 5.56332 9.12934 5.26366C8.82968 4.964 8.42378 4.79484 8 4.793C7.57751 4.79895 7.17399 4.96943 6.87521 5.2682C6.57643 5.56698 6.40595 5.9705 6.4 6.393H4.8C4.8 5.54431 5.13715 4.73037 5.73726 4.13026C6.33738 3.53014 7.15131 3.193 8 3.193C8.8487 3.193 9.66263 3.53014 10.2627 4.13026C10.8629 4.73037 11.2 5.54431 11.2 6.393C11.2 8.388 8.8 8.615 8.8 10.385Z"
                                  fill="#3892EF" />
                              </svg>
                            </span>
                            <p className="m-0 ms-1 fs-label fw-normal d-inline">Leave at zero (0) if voting is free</p>
                          </div>
                        </div>
                      </UncontrolledCollapse>
                    </div>
                  </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-12">
                            <h4 className="text-uppercase text-dark fs-label fw-semi-bold mb-2">voting starts<span className="text-danger">*</span></h4>
                            <p className="m-0 desc">Set when voting for your contest will commence</p>
                          </div>
                          <div className="col-12">
                            <input type="date"
                              className='col-md-6 form-control mt-2'
                              defaultValue={format(new Date(votingStarts || Date.now()), 'yyyy-MM-dd')}
                              onChange={(e) => updateDateValue({ votingStarts }, e.target.value)}
                            />
                            <input type="time"
                              className="col-md-6 form-control mt-2"
                              defaultValue={format(new Date(votingStarts || Date.now()), 'HH:mm')}
                              onChange={(e) => updateTimeValue({ votingStarts }, e.target.value)}
                            />
                          </div>
                          {errors?.votingStarts?.message && <small className='text-danger text-left m-0'>{errors?.votingStarts?.message}</small>}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3 mt-md-0">
                        <div className="row">
                          <div className="col-12">
                            <h4 className="text-uppercase text-dark fs-label fw-semi-bold mb-2">voting ends<span className="text-danger">*</span></h4>
                            <p className="m-0 desc">Set when voting for your contest will close</p>
                          </div>
                          <div className="col-12">
                            <input type="date"
                              className='col-md-6 form-control mt-2'
                              defaultValue={format(new Date(votingEnds || Date.now()), 'yyyy-MM-dd')}
                              onChange={(e) => updateDateValue({ votingEnds }, e.target.value)}
                            />
                            <input type="time"
                              className="col-md-6 form-control mt-2"
                              defaultValue={format(new Date(votingEnds || Date.now()), 'HH:mm')}
                              onChange={(e) => updateTimeValue({ votingEnds }, e.target.value)}
                            />
                          </div>
                          {errors?.votingEnds?.message && <small className='text-danger text-left m-0'>{errors?.votingEnds?.message}</small>}
                        </div>
                      </div>
                    </div>
                  </div>
                </UncontrolledCollapse>
              </div>
            </div>
          </section>
        </>}
      </ModalBody>
      {contestCreated && <ModalFooter>
        <div className="d-flex justify-content-end align-items-center">
          <button
            onClick={() => handleCreateContest(ContestStatus.Draft)}
            disabled={!isValid}
            className="accordion-cta btn bg-transparent btn-outline me-3">
            Proceed {status === ContestStatus.Draft && isSubmitting && <Icon name="IoEllipsisHorizontal" className='ml-2'></Icon>}
          </button>
          {/* <button
            onClick={() => handleCreateContest(ContestStatus.Published)}
            disabled={!isValid}
            className="accordion-cta btn bg-primary text-light">
            Publish Content
            {status === ContestStatus.Published && isSubmitting && <Icon name="IoEllipsisHorizontal" className='ml-2'></Icon>}
          </button> */}
        </div>
      </ModalFooter>}
    </Modal>
  );
}

export default CreateContestModal;
