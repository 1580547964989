const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;
import 'react-quill/dist/quill.snow.css';

const RichTextInput = ({ value, onChange }: { value?: string; onChange: (richData: string) => void }) => {

    const modules = {
        toolbar: [
            // [{ font: [] }],
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "strike"],
            // [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            // ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["link", "image"],
            // ["clean"],
        ],
    };


    return (
        <ReactQuill className="richText-input" modules={modules} theme="snow" placeholder="Give detailed information about your contest" value={value} onChange={onChange} />
    )

}

export default RichTextInput