import { IUser } from '@services/user/types';
import { Document, IApiResponse } from '@helpers/api/types';
import { IMoney } from '@services/shared/types';
import { VotingRules } from '../vote/types';
import { IForm } from '@services/contestForm/types';

export interface CreateContestOptions {
  title: IContest['title'];
  organization?: IContest['organization'];
  image?: IContest['image'];
  isContestPrivate?: IContest['isContestPrivate'];
  description?: IContest['description'];
  status?: IContest['status'];
  isRegistrationPrivate?: IContest['isRegistrationPrivate'];
  entryFee?: {
    currency: IMoney["currency"],
    value: number
  };
  registrationStarts?: IContest['registrationStarts'];
  registrationEnds?: IContest['registrationEnds'];
  isVotingPrivate?: IContest['isVotingPrivate'];
  votingStarts?: IContest['votingStarts'];
  votingEnds?: IContest['votingEnds'];
  votingFee?: {
    currency: IMoney["currency"],
    value: number
  };
}

export enum ContestStatus {
  Draft = 'draft',
  Published = 'published',
  Deleted = 'deleted'
}

export interface IContest extends Document {
  title: string;
  slug: string;
  organization: string;
  author: IUser;
  image: string;
  isContestPrivate: boolean;
  description: string;
  status: ContestStatus;
  isRegistrationPrivate: boolean;
  entryFee: IMoney;
  registrationStarts: string;
  registrationEnds: string;
  isVotingPrivate: boolean;
  votingStarts: string;
  votingEnds: string;
  votingFee: IMoney;
  votingRule: VotingRules;
  firstSave: boolean;
}

interface IContestExtras { nContestants: number; nVoters: number; nVotes: number; hasEntryForm: boolean, entryForm: Pick<IForm, '_id' | 'status'> }

export interface IContestApiResponse extends IApiResponse {
  data: IContest;
}

export interface IGetSingleContestApiResponse extends IApiResponse {
  data: IContest & IContestExtras;
}

export interface IGetContestsApiResponse extends IApiResponse {
  data: (IContest & IContestExtras)[];
}
export interface IContestPublishEligibility {
  isEligible: boolean;
  message: string;
}

export interface IContestPublishEligibilityApiResponse extends IApiResponse {
  data: IContestPublishEligibility
} 