export enum Currency {
  NGN = 'NGN',
  USD = 'USD'
}

export interface IMoney {
  currency: Currency;
  value: string;
}

export enum PaymentReason {
  Topup = 'topup',
  Purchase = 'purchase'
}

export enum TransactionTypes {
  Subscription = 'SubscriptionTransaction',
  Vote = 'VoteTransaction',
  Entry = 'EntryTransaction',
}

export interface Metadata {
  // required generally
  reason: PaymentReason;
  user: string;
  type: TransactionTypes;
  // required for subscription txns
  planIds?: string[];
  // required for vote txns
  contest?: string;
  contestant?: string;
  nVotes?: number;
}