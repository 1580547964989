import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import _ from 'lodash';
import Link from 'next/link';
import { IUser } from '@services/user/types';
import Avatar from '@components/Avatar';
import Icon from '@components/Icon';
import SubscriptionStatusBadge from '@components/SubscriptionStatusBadge';

interface ProfileMenuDropdownProps {
  target: string;
  user?: IUser;
}

export default function ProfileMenuDropdown({ target, user }: ProfileMenuDropdownProps) {
  return (
    <UncontrolledPopover
      fade={false}
      placement='bottom-end'
      hideArrow={false}
      target={target}
      popperClassName='border-0'
      style={{ border: '1px solid #E0E7FF', marginTop: '8px', background: '#fff', borderRadius: '10px', boxShadow: '-10px 10px 15px #54555529' }}>
      <PopoverBody className='p-0'>
        <ul className="profile-dropdown p-0 m-0">
          <li style={{ "borderRadius": "10px 10px 0 0" }}>
            <Link passHref href='/dashboard' legacyBehavior>
              <div className="d-flex align-items-center user-profile px-3 py-2">
                <div className="user-avatar me-3">
                  <Avatar src={user?.avatar} name={user?.name} size='48' round={true} className='mr-2' />
                </div>
                <div>
                  <p className="name text-capitalize m-0">
                    {user?.name}
                  </p>
                  <p className="email m-0">
                    <SubscriptionStatusBadge status={user?.activePlan?.title as any} />
                  </p>
                </div>
              </div>
            </Link>
          </li>
          <li className="px-3 py-2 dropdown-item">
            <Link href='/dashboard' className='text-decoration-none btn'>

              <Icon name='IoPodium' />my dashboard
            </Link>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li className="px-3 py-2 dropdown-item">
            <Link href='/dashboard/subscriptions' className='text-decoration-none btn'>

              <Icon name='IoBagCheck' />subscriptions
            </Link>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li className="px-3 py-2 dropdown-item">
            <Link href='/logout' className='text-decoration-none btn btn-link text-danger'>

              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                  <path
                    d="M10 8.66667C9.63133 8.66667 9.33333 8.96533 9.33333 9.33333V12C9.33333 12.3673 9.03467 12.6667 8.66667 12.6667H6.66667V2.66667C6.66667 2.09733 6.304 1.58867 5.75867 1.39933L5.56133 1.33333H8.66667C9.03467 1.33333 9.33333 1.63267 9.33333 2V4C9.33333 4.368 9.63133 4.66667 10 4.66667C10.3687 4.66667 10.6667 4.368 10.6667 4V2C10.6667 0.897333 9.76933 0 8.66667 0H1.5C1.47467 0 1.45333 0.0113333 1.42867 0.0146667C1.39667 0.012 1.366 0 1.33333 0C0.598 0 0 0.598 0 1.33333V13.3333C0 13.9027 0.362667 14.4113 0.908 14.6007L4.92 15.938C5.056 15.98 5.19133 16 5.33333 16C6.06867 16 6.66667 15.402 6.66667 14.6667V14H8.66667C9.76933 14 10.6667 13.1027 10.6667 12V9.33333C10.6667 8.96533 10.3687 8.66667 10 8.66667Z"
                    fill="#FF4848" />
                  <path
                    d="M15.8048 6.19538L13.1381 3.52871C12.9474 3.33804 12.6608 3.28071 12.4114 3.38404C12.1628 3.48738 12.0001 3.73071 12.0001 4.00004V6.00004H9.33342C8.96542 6.00004 8.66675 6.29871 8.66675 6.66671C8.66675 7.03471 8.96542 7.33338 9.33342 7.33338H12.0001V9.33338C12.0001 9.60271 12.1628 9.84604 12.4114 9.94938C12.6608 10.0527 12.9474 9.99538 13.1381 9.80471L15.8048 7.13804C16.0654 6.87738 16.0654 6.45604 15.8048 6.19538Z"
                    fill="#FF4848" />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>log out
            </Link>
          </li>
        </ul>
      </PopoverBody>
    </UncontrolledPopover >
  );
}
