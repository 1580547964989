import { NextPage } from 'next';
import { createAvatarComponent, ReactAvatarProps, SrcSource, ValueSource } from 'react-avatar';

const Avatar: NextPage<ReactAvatarProps> = (props) => {
  const ReactAvatar = createAvatarComponent({
    sources: [SrcSource, ValueSource]
  });

  return (<ReactAvatar {...props} />);
}

export default Avatar;