import { Document, IApiResponse } from "@helpers/api/types";
import { IMoney } from "@services/shared/types";

export enum PlanType {
  Free = "free",
  Basic = "basic",
  Premium = "premium",
  VIP = "vip",
}

export enum PlanVariants {
  Ambassador = "ambassador",
  Normal = "normal",
}

export enum PlanTitle {
  PremiumVoter = "premium voter",
  PremiumOrganizer = "premium organizer",
  PlatinumOrganizer = "platinum organizer",
  Ambassador = "ambassador",
  Starter = "starter",
}

export interface ICreatePlanOptions {
  title: IPlan["title"];
  features: IPlan["features"];
  price: IPlan["price"];
  subTitle: IPlan["subTitle"];
  monthlyDiscount: IPlan["monthlyDiscount"];
  yearlyDiscount: IPlan["yearlyDiscount"];
  type: IPlan["type"];
  gatewayCode: IPlan["gatewayCode"];
}

export interface IPlan extends Document {
  title: PlanTitle;
  features: string[];
  price: IMoney;
  subTitle?: string;
  monthlyDiscount?: number; // value in percentage.
  yearlyDiscount?: number;
  isActive?: boolean;
  type: PlanType;
  variant: PlanVariants;
  gatewayCode: string;
}

export interface IPlansApiResponse extends IApiResponse {
  data: IPlan[];
}
export interface IUpdateSubscriptionCardApiResponse extends IApiResponse {
  data: { link: string; };
}
