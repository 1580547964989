import { PlanTitle } from "@services/plan/types";

interface SubscriptionStatusBadgeProps {
  status: PlanTitle;
  isPending?: boolean;
}

const statusMap = {
  [PlanTitle.PremiumVoter]: "bg-primary bg-opacity-25 text-primary",
  [PlanTitle.PremiumOrganizer]: "bg-secondary bg-opacity-25 text-secondary",
  [PlanTitle.PlatinumOrganizer]: "bg-dark text-light",
  [PlanTitle.Ambassador]: "bg-dark text-light",
}

const SubscriptionStatusBadge: React.FC<SubscriptionStatusBadgeProps> = ({ status, isPending }) => {
  return (<>
    <span className={`badge rounded-pill text-uppercase ${isPending ? "text-dark bg-white" : (statusMap as any)[status?.toLowerCase()]}`}>{isPending ? "pending" : status}</span>
  </>);
};

export default SubscriptionStatusBadge;
