import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContestStatus } from './types';

const baseSchema = yup.object({
  title: yup.string().min(3).required(),
  image: yup.string().optional().nullable(),
  description: yup.string().min(3).nullable().optional(),
  isContestPrivate: yup.boolean().required(),
  isRegistrationPrivate: yup.boolean().optional(),
  isVotingPrivate: yup.boolean().optional(),
  votingStarts: yup.date().optional().nullable(),
  votingEnds: yup.date().optional().nullable(),
  votingFee: yup.object({
    value: yup.number(),
    currency: yup.string()
  }).optional().nullable(),
  registrationEnds: yup.date().optional().nullable(),
  registrationStarts: yup.date().optional().nullable(),
  entryFee: yup.object({
    value: yup.number(),
    currency: yup.string()
  }).optional().nullable(),
  organization: yup.string().length(24).optional().nullable(),
  status: yup.string().matches(new RegExp(Object.values(ContestStatus).join('|'))).optional(),
})

export const CreateContestSchema = yupResolver(baseSchema);

export const UpdateContestSchema = yupResolver(baseSchema);
