import Icon from "@components/Icon";

interface LogoProps {
  showIcon?: boolean;
  showText?: boolean;
}

const Logo = ({ showIcon = true, showText = true }: LogoProps) => {
  return (
    <span>
      {showIcon && <Icon name="brand" className="me-2" />}
      {showText && <>contest<strong>&bull;<em>ish</em></strong></>}
    </span>
  )
}

export default Logo;
